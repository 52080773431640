import styled from 'styled-components'
import {Col, Row} from 'react-bootstrap'


export const WideRow = styled(Row)`
width: 100%;
`;

export const WideCol = styled(Col)`
width: 100%;
`;
